import React, { FC, useState } from 'react'
import * as pageUtils from './__details.utils'
import { formatStrapiText, getLabelFromList } from '@utils/methods'
import { RadioList } from '@utils/request'
import * as formUtils from '../../requestForms/__forms.utils'
import TableDetailsModal from '../modals/tableDetailsModal'

const FireHydrantsTankFillingFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const getLabelsFromString = (
		list: RadioList[],
		answer?: string
	): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.label)
	}

	const [isFirstModalOpen, setIsFirstModalOpen] = useState<boolean>(false)

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_company_information}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_annualRegistrationCertificateForContractors_companyName_label
					}
				</b>
				<br />
				<span>{request.incidentForm?.business?.organization_name}</span>
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_isSameAdresse_label}</b>
				<br />
				<span>
					{getLabelFromList(
						radioListYesNo,
						request.incidentForm?.businessSameAsPersonal
					)}
				</span>
			</p>

			{request.incidentForm?.businessSameAsPersonal?.toString() == 'false' && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>{pageAssets.label_address}</b>
						<br />
						{request.incidentForm?.business?.address}
						<br />

						{/* en cas de adresse en dehors de Laval */}
						{!!request.incidentForm?.business?.city && (
							<>{request.incidentForm?.business?.city} </>
						)}
						{!!request.incidentForm?.business?.postal_code && (
							<>{request.incidentForm?.business?.postal_code} </>
						)}
						{!!request.incidentForm?.business?.state && (
							<>{request.incidentForm?.business?.state} </>
						)}
						{!!request.incidentForm?.business?.country && (
							<>
								{request.incidentForm?.business?.country} <br />
							</>
						)}
						{/* ---------------------------------- */}

						{request.incidentForm?.business?.appartment
							? `${formatStrapiText(pageAssets?.label_apartment_office)} ${
									request.incidentForm?.business?.appartment
							  }`
							: ''}
					</p>
				</>
			)}

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_hydraulicAndPressureTest_is_this_a_contract_with_city
					}
				</b>
				<br />
				<span>
					{getLabelFromList(
						radioListYesNo,
						request.incidentForm?.isContractWithCity
					)}
				</span>
			</p>

			{request.incidentForm?.isContractWithCity?.toString() == 'true' && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{pageAssets.request_form_hydraulicAndPressureTest_contract_number}
						</b>
						<br />
						<span>{request.incidentForm?.contractNumber}</span>
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{
								pageAssets.request_form_hydraulicAndPressureTest_name_of_respondent_at_city
							}
						</b>
						<br />
						<span>{request.incidentForm?.cityRespondentName}</span>
					</p>
				</>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_fireHydrantsTankFilling_step2_title}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_fireHydrantsTempAqueductNetwork_startDate_label
					}
				</b>
				<br />
				<span>{pageUtils.getLocaleDate(request.incidentForm?.startDate!)}</span>
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_fireHydrantsTempAqueductNetwork_endDate_label
					}
				</b>
				<br />
				<span>{pageUtils.getLocaleDate(request.incidentForm?.endDate!)}</span>
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_fireHydrantsTempAqueductNetwork_whichFireHydrantToUse_label
					}
				</b>
				<br />
				<span>
					{request.incidentForm?.isContractWithCity?.toString() == 'true' ? (
						request.incidentForm?.whichFireHydrantToUse
					) : (
						<>
							{getLabelsFromString(
								formUtils.fireHydrantsLocations,
								request.incidentForm?.whichFireHydrantsToUseList
							).map((label, index) => {
								return (
									<p
										className={pageUtils.classes.listMargin}
										key={`${index}-whichFireHydrantsToUseList`}
									>
										{label}
									</p>
								)
							})}
						</>
					)}
				</span>
			</p>

			{request.incidentForm?.typeOfUse && (
				<p className={pageUtils.classes.paragraph}>
					<b>
						{pageAssets.request_form_fireHydrantsTankFilling_typeOfUse_label}
					</b>
					<br />
					<span>{request.incidentForm?.typeOfUse}</span>
				</p>
			)}

			<h4 className={pageUtils.classes.h4}>
				{formatStrapiText(
					pageAssets?.request_form_fireHydrantsTankFilling_vehicule_title
				)}
			</h4>

			<p>
				<span
					onClick={() => {
						setIsFirstModalOpen(true)
					}}
					className={pageUtils.classes.viewDetailsSpan}
				>
					<b>{pageAssets.request_form_show_details}</b>
				</span>
			</p>

			<TableDetailsModal
				isModalOpen={isFirstModalOpen}
				close={() => {
					setIsFirstModalOpen(false)
				}}
				data={request?.incidentForm?.vehicleInformationList}
				title={pageAssets.request_form_fireHydrantsTankFilling_vehicule_title}
				headers={[
					pageAssets?.request_form_fireHydrantsTankFilling_tankTruckPlate_label,
					pageAssets?.request_form_fireHydrantsTankFilling_tankTruckVolume_label,
					pageAssets?.request_form_fireHydrantsTankFilling_tankTruckFrequency_label
				]}
				attributes={[
					{ name: 'plate_number', type: 'string' },
					{ name: 'tank_volume', type: 'string' },
					{ name: 'frequency_of_use', type: 'string' }
				]}
			/>

			{request.incidentForm?.additionalInfo && (
				<p>
					<b>{pageAssets?.request_form_any_additional_information}</b>

					<br />
					<span>{request.incidentForm?.additionalInfo}</span>
				</p>
			)}
		</>
	)
}

export default FireHydrantsTankFillingFormDetails
